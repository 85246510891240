<template>
    <div class=" mx-auto">
        <div>
            <ProcesoExitoso />
        </div>
    </div>
</template>

<script>
import ProcesoExitoso from '@/components/Cuenta/PagoParteSocial/ProcesoExitoso.vue'
export default {
    data(){
        return {}
    },
    name: 'PagoExitoso',
    components: {
        ProcesoExitoso
    },
    methods:{
        redirect() {
            // window.location.href ="/cuenta/principal";
            this.$router.push({path:"/cuenta/principal"});
        }
    }
}
  
</script>



<style>

@media (min-width: 766px) {
    .collapse.dont-collapse-sm {
        display: block;
        height: auto !important;
        visibility: visible;
    }
    .border{
        border-right: 1px dashed #333;
        border-top: 1px dashed #ffffff;
        border-left: 1px dashed #ffffff;
        border-bottom: 1px dashed #ffffff;
    }
}

@media (max-width: 766px) {
    .border{
        margin-left: 9px;
        margin-right: -15px;
        border-right: 1px dashed #ffffff;
        border-top: 1px dashed #ffffff;
        border-left: 1px dashed #ffffff;
        border-bottom: 1px dashed #ffffff;
    }
}


#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}

@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}

</style>
